<template>
  <div>
    <b-row>
      <b-col md="3" class="ml-auto">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filter-input"
              :disabled="
                !Array.isArray(getListOrders) ||
                (getListOrders.length === 0 && isBusy === false)
              "
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" class="table-responsive">
        <b-table
          hover
          :busy="
            !Array.isArray(getListOrders) || getListOrders.length === 0
              ? true
              : false || bussy == true
          "
          :items="getListOrders"
          :fields="columns"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="filter"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <div v-if="bussy">
                <b-spinner type="grow" class="align-middle"></b-spinner>
                <strong> Cargando datos...</strong>
              </div>
              <span class="h5" v-else>Sin registros</span>
            </div>
          </template>
          <template v-slot:cell(area)="data">
            {{ data.item.area.name }}
          </template>
          <template v-slot:cell(user_applicant)="data">
            {{ data.item.user_applicant.fullname }}
          </template>
          <template v-slot:cell(option)="data">
            <b-dropdown class="m-2" text="Primary" variant="primary" size="sm">
              <template #button-content> </template>
              <!-- VER -->
              <b-dropdown-item
                v-can="'products.view'"
                v-if="type == 1"
                :to="{
                  name: 'orders_requests.show',
                  params: { id: data.item.id },
                }"
              >
                <i class="ri-eye-line mr-2 h5 text-primary"></i> Ver detalles
              </b-dropdown-item>
              <!-- COMENTARIO -->
              <b-dropdown-item
                v-can="'purchase.order.view'"
                @click="$refs.comentaries.show('oc', data.item.id)"
              >
                <i class="ri-chat-smile-3-line mr-2 h5 text-info"></i>Nuevo
                comentario rápido
              </b-dropdown-item>
              <!-- ELIMINAR -->
              <b-dropdown-item
                v-can="'purchase.order.delete'"
                v-if="type == 1 && data.item.status === 1"
                @click="showMsgBoxTwo(data.item.id)"
              >
                <i class="ri-delete-bin-line mr-2 h5 text-dark"></i>Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          class="mt-3"
          v-model="currentPage"
          :total-rows="totalRows == 0 ? getListOrders.length : totalRows"
          :per-page="perPage"
          first-number
          pills
          size="sm"
          align="center"
        ></b-pagination>
        <Comentaries ref="comentaries" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import Comentaries from "@/components/core/modals/Comentaries.vue";
export default {
  components: {
    Comentaries,
  },
  props: {
    type: null,
    bussy: { default: false },
  },
  async mounted() {
    core.index();
  },
  data() {
    return {
      columns: [
        { label: "N°", key: "correlative", class: "text-center" },
        { label: "Fecha", key: "created_at", class: "text-center" },
        { label: "Área", key: "area", class: "text-center" },
        { label: "Estado", key: "status_description", class: "text-center" },
        { label: "Solicitante", key: "user_applicant", class: "text-center" },
        { label: "Descripción", key: "description", class: "text-center" },
        { label: "Opciones", key: "option", class: "text-lefght" },
      ],
      rows: [],
      perPage: 10,
      currentPage: 1,
      filter: null,
      totalRows: 0,
      isBusy: false,
    };
  },
  methods: {
    default() {
      return {
        created_at: "",
        area: "",
        status_description: "",
      };
    },
    ...mapActions({
      destroyPurchaseOrders: "orders/destroyPurchaseOrders",
    }),
    showMsgBoxTwo(item) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("¿Desea eliminar este registro?.", {
          title: "Confirmación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          value ? this.remove(item) : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async remove(item) {
      try {
        await this.destroyPurchaseOrders(item);
        core.showSnackbar("success", "Orden eliminada correctamente");
      } catch (error) {
        console.log(error);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters({
      getListOrders: "orders/getListOrders",
    }),
    lengt() {
      return this.getListOrders.length;
    },
  },
};
</script>
