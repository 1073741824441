<template>
  <b-container v-can="'purchase.order.view'">
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Listado De Órdenes de Compra</h4>
          </template>
          <template v-slot:headerAction>
            <b-button
              variant="primary"
              class="mr-2"
              :to="{ name: 'orders_requests.register' }"
              v-can="'purchase.order.create'"
            >
              Nueva Orden De Compra
            </b-button>
          </template>
          <template v-slot:body>
            <table-orders :bussy="isBusy" :type="route"></table-orders>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions } from "vuex";
import TableOrders from "@/components/core/table/TableOrderQuotations";
export default {
  name: "List",
  components: {
    TableOrders,
  },
  async created() {
    this.isBusy = true;
    await this.listPurchaseOrders();

    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    this.isBusy = false;
  },
  data() {
    return {
      route: 1,
      isBusy: false,
      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Órdenes de Compras",
          active: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      listPurchaseOrders: "orders/listPurchaseOrders",
      
    }),
  },
};
</script>
<style>
</style>
