<template>
  <b-modal id="comentaries" size="lg" hide-footer :title="title">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider
          name="Comentario"
          rules="required|min:3"
          v-slot="{ errors }"
        >
          <b-form-textarea
            id="comentaries"
            v-model="form.commentary"
            placeholder="Ingresa tu comentario a publicar"
            rows="1"
            max-rows="1"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          ></b-form-textarea>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
        <hr />
        <b-button variant="primary" class="mr-2" type="submit"
          >Guardar</b-button
        >
        <b-button
          variant=" iq-bg-danger mr-1"
          class="mr-2"
          @click="$bvModal.hide('comentaries')"
          >Cerrar</b-button
        >
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import { core } from "@/config/pluginInit";
export default {
  name: "Comentaries",
  data() {
    return {
      form: {
        model: "",
        model_id: "",
        commentary: "",
      },
      title: "",
      isBusy: false,
    };
  },
  methods: {
    show(model, id) {
      this.title =
        model === "sc"
          ? "Comentario de solicitudes de compras"
          : "Comentario de órdenes de compra";
      this.form.model = model;
      this.form.model_id = id;
      this.$bvModal.show("comentaries");
    },
    async onSubmit() {
      try {
        await this.$store.dispatch("comentaries/storeComentaries", this.form);
        core.showSnackbar("success", "Registro Agregado");
        this.form = {
          model: "",
          model_id: "",
          commentary: "",
        };
        this.$bvModal.hide("comentaries");
      } catch (error) {
        this.form = {
          model: "",
          model_id: "",
          commentary: "",
        };
        this.$bvModal.hide("comentaries");
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
  },
};
</script>
